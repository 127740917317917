import React, { useState, useEffect } from "react";
import Header from "components/Header";
import { Box, Button, TextField, IconButton, InputAdornment, useTheme, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, Search,Visibility  } from "@mui/icons-material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { message } from "antd";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDeleteTopDealByIdMutation } from "state/api";
import styled from "styled-components";

const CustomSearchBar = ({ searchInput, setSearchInput, setSearch }) => {
  return (
    <GridToolbarContainer>
      <Box display="flex" justifyContent="space-between" width="100%">
        <TextField
          label="Search Ref No..."
          sx={{ mb: "0.5rem", width: "15rem" }}
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setSearch(searchInput)}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </GridToolbarContainer>
  );
};

const StyledContent = styled(Typography)`
  white-space: pre-wrap;
`;

const TopDeals = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState(null);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [deleteTopDealById] = useDeleteTopDealByIdMutation();
  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchTopDeals = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://cupstonetravel.com:8444/management/top-deals", {
        method: "GET",
      });
      const result = await response.json();
      if (response.ok) {
        setData(result.data);
        setFilteredData(result.data); // Initially display all rows
      } else {
        console.error("Error fetching top deals:", result.message);
      }
    } catch (error) {
      console.error("Error fetching top deals:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTopDeals();
  }, []);

  useEffect(() => {
    if (search) {
      setFilteredData(
        data.filter((row) =>
          row.refNo.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredData(data); // Reset to all rows if search is cleared
    }
  }, [search, data]);

  const handleDeleteClick = (dealId) => {
    setSelectedDealId(dealId);
    setOpenDialog(true);
  };

  const handleViewClick = (deal) => {
    setSelectedDeal(deal);
    setOpenViewDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteTopDealById(selectedDealId).unwrap();
      message.success("Top Deal deleted successfully");
      fetchTopDeals(); // Refresh data after deletion
    } catch (error) {
      message.error("Failed to delete Top Deal");
    } finally {
      setOpenDialog(false);
    }
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };

  const columns = [
    {
      field: "photos",
      headerName: "Photos",
      flex: 1,
      width: 100,
      renderCell: (params) => {
        const photoUrl = params.value[0]?.url;
        return (
          <img
            src={photoUrl}
            alt="Top Deal"
            style={{ width: "100%", height: "100%", objectFit: 'cover' }}
          />
        );
      },
    },
    {
      field: "refNo",
      headerName: "Ref No",
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "destinationGroup",
      headerName: "Destination Group",
      flex: 0.8,
    },
    {
      field: "priceStart",
      headerName: "Price KES",
      flex: 0.5,
    },
    {
      field: "discountDeal",
      headerName: "Discount %",
      flex: 0.5,
    },
    {
      field: 'view',
      headerName: 'View',
      flex: 0.5,
      renderCell: (params) => (
          <Box>
              <Button
                  variant="contained"
                  color="success"
                  endIcon={<Visibility/>}
                  onClick={() => handleViewClick(params.row)}
                  sx={{ mr: 1 }}
              >
                  View
              </Button>
          </Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDeleteClick(params.row._id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Top Deals" subtitle="Entire list of Top Deals" />

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={filteredData} // Use filteredData here
          columns={columns}
          rowHeight={100}
          components={{
            Toolbar: () => (
              <CustomSearchBar
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                setSearch={setSearch}
              />
            ),
          }}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: "1.5rem" }}
      >
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.secondary[100],
            borderColor: theme.palette.secondary[300],
          }}
          endIcon={<Add />}
          onClick={() => navigate("/createtopdeal")}
        >
          Add Deal
        </Button>
      </Box>

      {/* Deletion Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Top Deal?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* View Deal Dialog */}
      <Dialog open={openViewDialog} onClose={handleCloseViewDialog} maxWidth="md" fullWidth>
        <DialogTitle>Top Deal Details</DialogTitle>
        <DialogContent>
          {selectedDeal && (
            <Box>
              <Typography variant="h6">Ref No: {selectedDeal.refNo}</Typography>
              <Typography variant="body1">Title: {selectedDeal.title}</Typography>
              <Typography variant="body1">Destination Group: {selectedDeal.destinationGroup}</Typography>
              <Typography variant="body1">Price KES: {selectedDeal.priceStart}</Typography>
              <Typography variant="body1">Discount %: {selectedDeal.discountDeal}</Typography>
              {/* <Box mt={2}>
                <Typography variant="h6">Photos:</Typography>
                {selectedDeal.photos.map((photo, index) => (
                  <img
                    key={index}
                    src={photo.url}
                    alt={`Deal ${index}`}
                    style={{ width: "100%", height: "auto", marginTop: "10px" }}
                  />
                ))}
              </Box> */}
              <Typography variant="h6" mt={2}>Description</Typography>
              <StyledContent dangerouslySetInnerHTML={{ __html: selectedDeal.description }} />
              <Typography variant="h6" mt={2}>Inclusions</Typography>
              <StyledContent dangerouslySetInnerHTML={{ __html: selectedDeal.inclusions }} />
              <Typography variant="h6" mt={2}>Exclusions</Typography>
              <StyledContent dangerouslySetInnerHTML={{ __html: selectedDeal.exclusions }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TopDeals;
