import React, { useState} from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  Button,
  TextField,
  Card,
  CardContent,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetCarByIdQuery } from "state/api";
import Footer from "components/Footer";
import HomepageNav from "components/HomepageNav";
import styled from "styled-components";
import { motion } from "framer-motion";
import FloatingSocialIcons from "components/FloatingSocialIcons";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const StyledImage = styled(motion.img)`
  max-width: 100%; // Restrict to container width
  max-height: 200px; // Set a max height
  cursor: pointer;
  border-radius: 8px;
  object-fit: cover; // Ensure the image is fully contained within the set dimensions
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

const ContactForm = styled.form`
display: flex;
flex-direction: column;
gap: 1rem;
padding: 1rem;
background: #e1ebee;
border-radius: 8px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
width: 80%;

@media (max-width: 768px) {
  width: 90%;
  padding: 0.5rem;
}

@media (max-width: 480px) {
  width: 100%;
  padding: 0.5rem;
}
`;

const CarPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetCarByIdQuery(id);
  const [open, setOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  if (isLoading) {
    return <div>Loading...</div>;
  }

  const car = data?.data[0]; // Assuming data.data is an array

  if (!car) {
    return <div>Car not found</div>;
  }

  const handleClickOpen = (photo) => {
    setSelectedPhoto(photo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPhoto(null);
  };

  return (
    <div
      style={{ background: "#E8EDF4FF", minHeight: "100vh", color: "black" }}
    >
      <HomepageNav />
      <Box sx={{ p: 10 }}>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          style={{
            color: "#008000",
            marginBottom: "50px",
            fontWeight: "bolder",
          }}
        >
          {car.title}
        </Typography>

        <Grid
          container
          spacing={2}
          sx={{ mb: 4, border: 1, borderColor: "white", borderRadius: "8px" }}
        >
          {car.photos.map((photo, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledImage
                src={photo.url}
                alt={car.title}
                onClick={() => handleClickOpen(photo.url)}
                whileHover={{ scale: 1.05 }}
              />
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h2"
            component="h3"
            gutterBottom
            style={{ color: "#008000", fontWeight: "bold" }}
          >
            Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Price Per Day
                  </Typography>
                  <Typography variant="h6">KES: {new Intl.NumberFormat().format(car.price)}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Engine Size
                  </Typography>
                  <Typography variant="body1">Ltr: {car.engineSize}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Country Located
                  </Typography>
                  <Typography variant="body1">
                    Country: {car.country}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Sitting Capacity
                  </Typography>
                  <Typography variant="body1">
                    No: {car.sittingCapacity}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Drive Type
                  </Typography>
                  <Typography variant="body1">Type: {car.driveType}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Fuel Type
                  </Typography>
                  <Typography variant="body1">Type: {car.fuelType}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h2" sx={{ color: "#008000" }}>
            Description:
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: car.description }} />
        </Box>

        <Box
          sx={{
            mb: 4,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ContactForm action="https://formsubmit.co/2a0c46b72f8c29e6f41c51036dad2c82" method="POST" >
            <Typography
              variant="h2"
              component="h3"
              gutterBottom
              style={{ color: "#008000" }}
            >
              Enquire About This Car
            </Typography>
            <TextField
            variant="outlined"
            fullWidth
            required
            name="subject"
            value={`Enquiring about ${car.title}`}
            InputProps={{
              style: { color: 'black' },
              readOnly: true,
            }}

          />
            <TextField
              label="Full Name"
              variant="outlined"
              fullWidth
              required
              name="fullName"
              style={{ backgroundColor: "white", color: "black" }}
              InputProps={{ style: { color: 'black' } }}
              InputLabelProps={{ style: { color: "black" } }}
            />
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              required
              name="phoneNumber"
              style={{ backgroundColor: "white", color: "black" }}
              InputProps={{ style: { color: 'black' } }}
              InputLabelProps={{ style: { color: "black" } }}
            />
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              required
              name="email"
              style={{ backgroundColor: "white", color: "black" }}
              InputProps={{ style: { color: 'black' } }}
              InputLabelProps={{ style: { color: "black" } }}
            />
            <TextField
              label="Number of Days Needed"
              variant="outlined"
              fullWidth
              name="daysNeeded"
              type="number"
              style={{ backgroundColor: "white", color: "black" }}
              InputLabelProps={{ style: { color: "black" } }}
              InputProps={{ style: { color: 'black' } }}
              required
            />
             <div style={{ margin: "20px 0" }}>
                <label style={{ color: "black" }}>Start Date: </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM/dd/yyyy"
                  name="startDate"
                  required
                  style={{ backgroundColor: "white", color: "black" }}
                />
              </div>
              <div style={{ margin: "20px 0" }}>
                <label style={{ color: "black" }}>End Date: </label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="MM/dd/yyyy"
                  name="endDate"
                  required
                  style={{ backgroundColor: "white", color: "black" }}
                />
              </div>
            
            <TextField
              label="More Queries"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              name="queries"
              style={{ backgroundColor: "white", color: "black" }}
              InputProps={{ style: { color: 'black' } }}
              InputLabelProps={{ style: { color: "black" } }}
            />
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </ContactForm>
        </Box>
      </Box>
      <Footer />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <img src={selectedPhoto} alt={car.title} style={{ width: "100%" }} />
        </DialogContent>
      </Dialog>
      <FloatingSocialIcons/>
    </div>
  );
};

export default CarPage;
