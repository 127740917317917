import React, { useState } from 'react';
import Header from 'components/Header';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add, Visibility } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { message } from 'antd';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useGetVillasQuery, useDeleteVillaByIdMutation } from 'state/api';
import styled from 'styled-components';

const StyledContent = styled(Typography)`
  white-space: pre-wrap;
`;

const Villas = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data, isLoading } = useGetVillasQuery();
  const [deleteVillaById] = useDeleteVillaByIdMutation();
  const [openDialog, setOpenDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [selectedVillaId, setSelectedVillaId] = useState(null);
  const [selectedVilla, setSelectedVilla] = useState(null);

  const handleDeleteClick = (villaId) => {
    setSelectedVillaId(villaId);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteVillaById(selectedVillaId).unwrap();
      message.success("Villa deleted successfully");
    } catch (error) {
      message.error("Failed to delete Villa");
    } finally {
      setOpenDialog(false);
    }
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
  };

  const handleViewClick = (villa) => {
    setSelectedVilla(villa);
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };

  const columns = [
    {
      field: 'photos',
      headerName: 'Photos',
      flex: 1,
      width: 100,
      renderCell: (params) => {
        const photoUrl = params.value[0]?.url;
        return (
          <img src={photoUrl} alt="Villa" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        );
      },
    },
    {
      field: '_id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      renderCell: (params) => {
        const { street, estate, country, city } = params.value;
        return `${street}, ${estate}, ${city}, ${country}`;
      },
    },
    {
      field: 'view',
      headerName: 'View',
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="success"
          onClick={() => handleViewClick(params.row)}
          endIcon={<Visibility />}
        >
          View
        </Button>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDeleteClick(params.row._id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Villas" subtitle="Entire list of villas" />

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data?.data || []}
          columns={columns}
          rowHeight={100}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: '1.5rem' }}
      >
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.secondary[100],
            borderColor: theme.palette.secondary[300],
          }}
          endIcon={<Add />}
          onClick={() => navigate('/createvilla')}
        >
          Add Villa
        </Button>
      </Box>

      {/* Deletion Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Villa?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* View Villa Dialog */}
      <Dialog open={openViewDialog} onClose={handleCloseViewDialog} maxWidth="md" fullWidth>
        <DialogTitle>Villa Details</DialogTitle>
        <DialogContent>
          {selectedVilla && (
            <Box>
              <Typography variant="h6">ID: {selectedVilla._id}</Typography>
              <Typography variant="body1">Title: {selectedVilla.title}</Typography>
              <Typography variant="body1">Address: {`${selectedVilla.address.street}, ${selectedVilla.address.estate}, ${selectedVilla.address.city}, ${selectedVilla.address.country}`}</Typography>
               <Typography variant="body1">Bathrooms: {`${selectedVilla.basicFeatures.bathrooms} `}</Typography>
               <Typography variant="body1">Beds: {`${selectedVilla.basicFeatures.beds} `}</Typography>
               <Typography variant="body1">Bedrooms: {`${selectedVilla.basicFeatures.bedrooms} `}</Typography>
               <Typography variant="body1">Number of Guests: {`${selectedVilla.basicFeatures.guests} `}</Typography>
              <Typography variant="body1">Price KES: {selectedVilla.price}</Typography>
              
              {/* <Box mt={2}>
                <Typography variant="h6">Photos:</Typography>
                {selectedVilla.photos.map((photo, index) => (
                  <img
                    key={index}
                    src={photo.url}
                    alt={`Villa ${index}`}
                    style={{ width: "100%", height: "auto", marginTop: "10px" }}
                  />
                ))}
              </Box> */}
              <Typography variant="h6" mt={2}>Description</Typography>
              <StyledContent dangerouslySetInnerHTML={{ __html: selectedVilla.description }} />
              <Typography variant="h6" mt={2}>Unique Features</Typography>
              <StyledContent dangerouslySetInnerHTML={{ __html: selectedVilla.uniqueFeatures }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Villas;
