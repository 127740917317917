import React, { useMemo } from 'react';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "theme";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Dashboard from "scenes/dashboard";
import Users from "scenes/users";
import CreateUser from "pages/CreateUser";
import TourBlogs from "scenes/blogs";
import CreateBlog from "pages/CreateBlog";
import Destinations from "scenes/destinations";
import CreateDestination from "pages/CreateDestination";
import TopDeals from "scenes/topdeals";
import CreateTopDeal from "pages/CreateTopDeal";
import Villas from "scenes/villas";
import CreateVilla from "pages/CreateVilla";
import CarFleet from "scenes/carfleet";
import CreateCar from "pages/CreateCar";
import HomePage from "pages/HomePage";
import Signin from "components/Signin";
import AirTickets from "pages/AirTickets";
import Logout from "scenes/logout";
import Blogs from "pages/Blogs";
import VillasPage from "pages/VillasPage";
import FleetPage from "pages/FleetPage";
import CarList from "pages/CarList";
import DestinationList from "pages/DestinationList";
import TopDealList from "pages/TopDealList";
import CarPage from "pages/CarPage";
import VillasDetails from "pages/VillasDetails";
import BlogPage from "pages/BlogPage";
import DestinationPage from "pages/DestinationPage";
import TopDealPage from "pages/TopDealPage";
import ContactPage from "pages/ContactPage";
import ProtectedRoute from "components/ProtectedRoute";
import ProtectedLayout from "components/ProtectedLayout";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/airtickets" element={<AirTickets />} />
            <Route path="/blogList" element={<Blogs />} />
            <Route path="/villasList" element={<VillasPage />} />
            <Route path="/fleetList" element={<FleetPage />} />
            <Route path="/carList/:category" element={<CarList />} />
            <Route path="/destinationList/:category" element={<DestinationList />} />
            <Route path="/topDealList/:category" element={<TopDealList />} />
            <Route path="/carPage/:id" element={<CarPage />} />
            <Route path="/villasDetails/:id" element={<VillasDetails />} />
            <Route path="/blogPage/:id" element={<BlogPage />} />
            <Route path="/destinationPage/:id" element={<DestinationPage />} />
            <Route path="/topDealPage/:id" element={<TopDealPage />} />
            <Route path="/contact" element={<ContactPage />} />

            {/* Protected routes */}
            <Route element={<ProtectedLayout />}>
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
              <Route path="/createuser" element={<ProtectedRoute><CreateUser /></ProtectedRoute>} />
              <Route path="/blogs" element={<ProtectedRoute><TourBlogs /></ProtectedRoute>} />
              <Route path="/createblog" element={<ProtectedRoute><CreateBlog /></ProtectedRoute>} />
              <Route path="/destinations" element={<ProtectedRoute><Destinations /> </ProtectedRoute>} />
              <Route path="/createdestination" element={<ProtectedRoute><CreateDestination /></ProtectedRoute>} />
              <Route path="/topdeals" element={<ProtectedRoute> <TopDeals /></ProtectedRoute>} />
              <Route path="/createtopdeal" element={<ProtectedRoute> <CreateTopDeal /></ProtectedRoute>} />
              <Route path="/managevillas" element={<ProtectedRoute> <Villas /></ProtectedRoute>} />
              <Route path="/createvilla" element={<ProtectedRoute><CreateVilla /></ProtectedRoute>} />
              <Route path="/carfleet" element={<ProtectedRoute><CarFleet /></ProtectedRoute>} />
              <Route path="/createcar" element={<ProtectedRoute><CreateCar /></ProtectedRoute>} />
              <Route path="/logout" element={<ProtectedRoute><Logout /></ProtectedRoute>} />
            </Route>

            {/* Redirect to home if no matching route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
