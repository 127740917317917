import React, { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  Container,
  TextField,
  Button,
  Grid,
  Card,
  CardContent
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetTopDealByIdQuery } from "state/api";
import Footer from "components/Footer";
import HomepageNav from "components/HomepageNav";
import styled from "styled-components";
import { motion } from "framer-motion";
import FloatingSocialIcons from "components/FloatingSocialIcons";

const StyledHeroImage = styled(motion.img)`
  width: 100%;
  height: 75vh; /* Set the height to 75vh */
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const StyledContent = styled.div`
  margin: 20px 0;
  line-height: 1.6;
`;

const StyledAuthorDate = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #888;
  margin-top: 20px;
`;

const FormBox = styled.form`
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #84B213FF;
`;

const StyledTextField = styled(TextField)`
  & label {
    color: #84B213FF;
  }
  & .MuiInputBase-input {
    color: #000;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #84B213FF;
    }
    &:hover fieldset {
      border-color: #84B213FF;
    }
    &.Mui-focused fieldset {
      border-color: #84B213FF;
    }
  }
`;

const SubmitButton = styled(Button)`
  background-color: #84B213FF;
  &:hover {
    background-color: #74a313;
  }
`;

const TopDealPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetTopDealByIdQuery(id);
  const [open, setOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const topDeal = data?.data[0]; // Assuming data.data is an array

  if (!topDeal) {
    return <div>Destination not found</div>;
  }

  const handleClickOpen = (photo) => {
    setSelectedPhoto(photo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPhoto(null);
  };

  return (
    <div
      style={{ background: "#E8EDF4FF", minHeight: "100vh", color: "black" }}
    >
      <HomepageNav />
      <Container maxWidth="lg" sx={{ p: 5 }}>
        <Box>
          {/* Hero Section */}
          <Box sx={{ mt: 5 }}>
            {topDeal.photos[0] && (
              <StyledHeroImage
                src={topDeal.photos[0].url}
                alt={topDeal.title}
                onClick={() => handleClickOpen(topDeal.photos[0].url)}
                whileHover={{ scale: 1.05 }}
              />
            )}
          </Box>

          {/* Title */}
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              color: "#008000",
              fontWeight: "bold",
              textAlign: "center",
              mt: 5,
            }}
          >
            {topDeal.title}
          </Typography>

          <StyledAuthorDate>
            <Typography variant="body2">{topDeal.country}</Typography>
          </StyledAuthorDate>

          <Box
            display="flex"
            mt={5}
            sx={{
              flexDirection: { xs: "column", md: "row" }, // Stack the boxes vertically on small screens
            }}
          >
            {/* 1st Column */}
            <Box flex={2} pr={{ xs: 0, md: 2 }} mb={{ xs: 3, md: 0 }}>
              <Box>
                <Typography
                  variant="h2"
                  component="h4"
                  gutterBottom
                  style={{ color: "#008000", fontWeight: "bold" }}
                >
                  Details
                </Typography>
                <Box sx={{ mb: 4 }}>
                  <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    style={{ color: "#008000" }}
                  >
                    Pricing
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Card>
                        <CardContent>
                          <Typography
                            variant="h4"
                            component="h4"
                            style={{ color: "#008000" }}
                          >
                            Reference No.
                          </Typography>
                          <Typography variant="h6">{topDeal.refNo}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Card>
                        <CardContent>
                          <Typography
                            variant="h4"
                            component="h4"
                            style={{ color: "#008000" }}
                          >
                            Price Start From
                          </Typography>
                          <Typography variant="body1">
                            KES:{" "}
                            {new Intl.NumberFormat().format(topDeal.priceStart)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Card>
                        <CardContent>
                          <Typography
                            variant="h4"
                            component="h4"
                            style={{ color: "#008000" }}
                          >
                            Discount Deal
                          </Typography>
                          <Typography variant="body1">
                            Discount: {topDeal.discountDeal}%
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
                <Typography
                  variant="h2"
                  component="h4"
                  gutterBottom
                  style={{ color: "#008000", marginTop: "50px" }}
                >
                  Description
                </Typography>
                <StyledContent
                  dangerouslySetInnerHTML={{ __html: topDeal.description }}
                />
              </Box>

              <Box>
                <Typography
                  variant="h2"
                  component="h4"
                  gutterBottom
                  style={{ color: "#008000", marginTop: "50px" }}
                >
                  Inclusions
                </Typography>
                <StyledContent
                  dangerouslySetInnerHTML={{ __html: topDeal.inclusions }}
                />
              </Box>
              <Box>
                <Typography
                  variant="h2"
                  component="h4"
                  gutterBottom
                  style={{ color: "#008000", marginTop: "50px" }}
                >
                  Exclusions
                </Typography>
                <StyledContent
                  dangerouslySetInnerHTML={{ __html: topDeal.exclusions }}
                />
              </Box>

              <StyledContent
                dangerouslySetInnerHTML={{ __html: topDeal.content }}
              />

              <StyledAuthorDate>
                <Typography variant="body2">By Cupstone Travel LTD</Typography>
                <Typography variant="body2">
                  {new Date(topDeal.createdAt).toLocaleDateString()}
                </Typography>
              </StyledAuthorDate>
            </Box>

            {/* 2nd Column */}
            <Box flex={1} pl={{ xs: 0, md: 2 }}>
              <FormBox
                action="https://formsubmit.co/d64a462948377481836474954a60ffbc"
                method="POST"
              >
                <Typography
                  variant="h4"
                  component="h4"
                  gutterBottom
                  sx={{ color: "#84B213FF" }}
                >
                  Inquire Now
                </Typography>
                <StyledTextField
                  variant="outlined"
                  fullWidth
                  required
                  name="Subject"
                  value={`Enquiring about ${topDeal.title}`}
                  InputProps={{
                    style: { color: "black" },
                    readOnly: true,
                  }}
                />
                <StyledTextField
                  fullWidth
                  margin="normal"
                  label="Your Name"
                  variant="outlined"
                  name="FullName"
                />
                <StyledTextField
                  fullWidth
                  margin="normal"
                  label="Your Email"
                  variant="outlined"
                  name="Email"
                />
                <StyledTextField
                  fullWidth
                  margin="normal"
                  label="Your Phone"
                  variant="outlined"
                  name="PhoneNumber"
                />
                <StyledTextField
                  fullWidth
                  margin="normal"
                  label="Reference No. i.e BV-123.."
                  variant="outlined"
                  name="TopDealReferenceNo"
                />
                <StyledTextField
                  fullWidth
                  margin="normal"
                  label="Your Enquiries"
                  variant="outlined"
                  name="Inquiries"
                  multiline
                  rows={4}
                />
                <SubmitButton
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2 }}
                  type="submit"
                >
                  Submit
                </SubmitButton>
              </FormBox>
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <img
            src={selectedPhoto}
            alt={topDeal.title}
            style={{ width: "100%" }}
          />
        </DialogContent>
      </Dialog>
      <FloatingSocialIcons />
    </div>
  );
};

export default TopDealPage;
