import {React, useState} from 'react'
import Header from 'components/Header'
import {Grid, TextField, Typography, Button, Box, Autocomplete } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { IoIosImages } from "react-icons/io";
import { BiTrash } from "react-icons/bi";
import ImageIcon from "@mui/icons-material/Image";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useCreateVillaMutation } from 'state/api';
import { message } from 'antd';
import { Counties } from 'state/countiesData';

const CreateVilla = () => {
  const [villaTitle, setVillaTitle] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [estate, setEstate] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [price, setPrice] = useState('');
  const [numberOfGuests, setNumberOfGuests] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [beds, setBeds] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [uniqueFeatures, setUniqueFeatures] = useState('');
  const [houseDescription, setHouseDescription] = useState('');
  const [photos, setPhotos] = useState([]);
  const [createVilla]= useCreateVillaMutation();

   // UPLOAD, DRAG AND DROP PHOTOS
   const handleUploadPhotos = (e) => {
    const newPhotos = e.target.files;
    setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
  };

  const handleDragPhoto = (result) => {
    if (!result.destination) return;
    const items = Array.from(photos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPhotos(items);
  };

  const handleRemovePhoto = (indexToRemove) => {
    setPhotos((prevPhotos) =>
      prevPhotos.filter((_, index) => index !== indexToRemove)
    );
  };
 // END UPLOAD, DRAG AND DROP PHOTOS

  const handleInputChange = (setter) => (event) => {
    const value = event.target.value;
    // Convert value to lowercase, handle cases where value might be null or undefined
    setter(value.toLowerCase());
  };
  
  const handleQuillChangeUniqueFeatures = (content) => {
    setUniqueFeatures(content);
  };
  
  const handleQuillChangeHouseDescription = (content) => {
    setHouseDescription(content);
  };


  //HANDLE SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData();
      formData.append('title', villaTitle);
      formData.append('address[street]', streetAddress);
      formData.append('address[estate]', estate);
      formData.append('address[country]', country);
      formData.append('address[city]', city);
      formData.append('price',price);
      formData.append('basicFeatures[guests]', numberOfGuests);
      formData.append('basicFeatures[bedrooms]', bedrooms);
      formData.append('basicFeatures[beds]', beds);
      formData.append('basicFeatures[bathrooms]', bathrooms);
      formData.append('uniqueFeatures', uniqueFeatures);
      formData.append('description', houseDescription);
  
      photos.forEach(photo => formData.append('images', photo));
  
      const response = await createVilla(formData).unwrap();
      setVillaTitle('');
      setStreetAddress('');
      setEstate('');
      setCountry('');
      setCity('');
      setPrice(0);
      setNumberOfGuests(0);
      setBedrooms(0);
      setBeds(0);
      setBathrooms(0);
      setUniqueFeatures('');
      setHouseDescription('');
      setPhotos([]);
      message.success('Villa created successfully');
      if(response.ok)
      {
        message.success('Villa created successfully');
      }
    } catch (error) {
      console.error('Error creating villa:', error);
      message.error(`Error creating villa: ${error.message}`);
    }
  };
  
  
  
  
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Create a New Villa" subtitle="Write and publish a new villa"/>
      <Box sx={{ my: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Villa Title"
              value={villaTitle}
              onChange={handleInputChange(setVillaTitle)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Street Address"
              value={streetAddress}
              onChange={handleInputChange(setStreetAddress)}            
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Estate"
              variant="outlined"
              value={estate}
              onChange={handleInputChange(setEstate)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Country"
              variant="outlined"
              value={country}
              onChange={handleInputChange(setCountry)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={Counties.map(county => county.name || county.capital)} // Use county names or capitals for options
              value={city}
              onChange={(event, newValue) =>  setCity(newValue ? newValue.toLowerCase() : '')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="City"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Price Start From"
              variant="outlined"
              value={price}
              onChange={handleInputChange(setPrice)}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h6" gutterBottom>
          Basic Features
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Number of Guests"
              variant="outlined"
              value={numberOfGuests}
              onChange={handleInputChange(setNumberOfGuests)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Bedrooms"
              variant="outlined"
              value={bedrooms}
              onChange={handleInputChange(setBedrooms)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Beds"
              variant="outlined"
              value={beds}
              onChange={handleInputChange(setBeds)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Bathrooms"
              variant="outlined"
              value={bathrooms}
              onChange={handleInputChange(setBathrooms)}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h6" gutterBottom>
          Unique Features
        </Typography>
        <ReactQuill
          theme="snow"
          value={uniqueFeatures}
          onChange={handleQuillChangeUniqueFeatures}
          modules={{
            toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              ['link', 'image'],
              ['clean']
            ],
          }}
          formats={[
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet',
            'link', 'image'
          ]}
          style={{ height: '200px' }}
        />
      </Box>

       {/* PHOTO INPUT  */}
       <Box sx={{ mt: 10 }}>
        <Button
          variant="outlined"
          startIcon={<ImageIcon />}
        >
          <DragDropContext onDragEnd={handleDragPhoto}>
            <Droppable droppableId="photos" direction="horizontal">
              {(provided) => (
                <div
                  className="photos"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {photos.length < 1 && (
                    <>
                      <input
                        id="image"
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleUploadPhotos}
                        multiple
                      />
                      <label htmlFor="image" className="alone">
                        <div className="icon">
                          <IoIosImages />
                        </div>
                        <p>Upload From Your Device</p>
                      </label>
                    </>
                  )}

                  {photos.length >= 1 && (
                    <>
                      {photos.map((photo, index) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="photo"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <img
                                src={URL.createObjectURL(photo)}
                                alt="blog"
                              />
                              <button
                                type="button"
                                onClick={() => handleRemovePhoto(index)}
                              >
                                <BiTrash />
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      <input
                        id="image"
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleUploadPhotos}
                        multiple
                      />
                      <label htmlFor="image" className="together">
                        <div className="icon">
                          <IoIosImages />
                        </div>
                        <p>Upload From Your Device</p>
                      </label>
                    </>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Button>
      </Box>

      {/* END OF PHOTO INPUT */}

      <Box sx={{ my: 4 }}>
        <Typography variant="h6" gutterBottom>
          House Description
        </Typography>
        <ReactQuill
          theme="snow"
          value={houseDescription}
          onChange={handleQuillChangeHouseDescription}
          modules={{
            toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              ['link', 'image'],
              ['clean']
            ],
          }}
          formats={[
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet',
            'link', 'image'
          ]}
          style={{ height: '200px' }}
        />
      </Box>

      <Box sx={{ textAlign: 'center', my: 8 }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Publish New Villa
        </Button>
      </Box>

    </Box>
  )
}

export default CreateVilla
