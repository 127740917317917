import React, { useState, useEffect } from 'react';
import Header from 'components/Header';
import { Box, Button, useTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add, Visibility } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { message } from 'antd';
import { useDeleteDestinationByIdMutation } from 'state/api';
import styled from "styled-components";

const StyledContent = styled.div`
  margin: 20px 0;
  line-height: 1.6;
`;

const Destinations = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDestinationById] = useDeleteDestinationByIdMutation();
    const [open, setOpen] = useState(false);
    const [destinationIdToDelete, setDestinationIdToDelete] = useState(null);
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [selectedDestination, setSelectedDestination] = useState(null);

    useEffect(() => {
        const fetchDestinations = async () => {
            try {
                const response = await fetch('https://cupstonetravel.com:8444/management/destinations', {
                    method: 'GET',
                });
                const result = await response.json();
                if (response.ok) {
                    setData(result.data);
                } else {
                    console.error('Error fetching destinations:', result.message);
                }
            } catch (error) {
                console.error('Error fetching destinations:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDestinations();
    }, []);

    const handleDeleteClick = (destinationId) => {
        setDestinationIdToDelete(destinationId);
        setOpen(true);
    };

    const handleViewClick = (destination) => {
        setSelectedDestination(destination);
        setOpenViewDialog(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDestinationIdToDelete(null);
    };

    const handleCloseViewDialog = () => {
        setOpenViewDialog(false);
        setSelectedDestination(null);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteDestinationById(destinationIdToDelete);
            setData(data.filter(destination => destination._id !== destinationIdToDelete));
            message.success("Destination deleted successfully");
        } catch (error) {
            console.error('Error deleting destination:', error);
            message.error("Failed to delete destination");
        } finally {
            handleClose();
        }
    };

    const columns = [
        {
            field: 'photos',
            headerName: 'Photos',
            flex: 1,
            width: 100,
            height: 100,

            renderCell: (params) => {
                const photoUrl = params.value[0]?.url;
                return (
                    <img src={photoUrl} alt="Destination" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                );
            },
        },
        {
            field: 'refNo',
            headerName: 'Ref No',
            flex: 1,
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
        },
        {
            field: 'country',
            headerName: 'Country',
            flex: 0.5,
        },
        {
            field: 'priceStart',
            headerName: 'Price KES',
            flex: 0.5,
        },
        {
            field: 'view',
            headerName: 'View',
            flex: 0.5,
            renderCell: (params) => (
                <Box>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Visibility/>}
                        onClick={() => handleViewClick(params.row)}
                        sx={{ mr: 1 }}
                    >
                        View
                    </Button>
                </Box>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 0.5,
            renderCell: (params) => (
                <Box>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteClick(params.row._id)}
                    >
                        Delete
                    </Button>
                </Box>
            ),
        },
    ];

    return (
        <Box m="1.5rem 2.5rem">
            <Header title="Destinations" subtitle="Entire list of Destinations" />
            <Box
                mt="40px"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    loading={isLoading || !data}
                    getRowId={(row) => row._id}
                    rows={data || []}
                    columns={columns}
                    rowHeight={100}
                />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ my: '1.5rem' }}>
                <Button
                    variant="outlined"
                    sx={{
                        color: theme.palette.secondary[100],
                        borderColor: theme.palette.secondary[300]
                    }}
                    endIcon={<Add />}
                    onClick={() => navigate('/createdestination')}
                >
                    Add Destination
                </Button>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Destination"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this destination?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openViewDialog} onClose={handleCloseViewDialog}>
                <DialogTitle>Destination Details</DialogTitle>
                <DialogContent>
                    {selectedDestination && (
                        <Box>
                            <Typography variant="body1">Price: {selectedDestination.refNo}</Typography>
                            <Typography variant="h6">{selectedDestination.title}</Typography>
                            <Typography variant="body1">Country: {selectedDestination.country}</Typography>
                            <Typography variant="body1">Price: {selectedDestination.priceStart}</Typography>
                            <Typography variant='h6'>Description</Typography>
                            <StyledContent dangerouslySetInnerHTML={{ __html: selectedDestination.description }} />
                            <Typography variant='h6'>Inclusions</Typography>
                            <StyledContent dangerouslySetInnerHTML={{ __html: selectedDestination.inclusions}} />
                            <Typography variant='h6'>Exclusions</Typography>
                            <StyledContent dangerouslySetInnerHTML={{ __html: selectedDestination.exclusions}} />
                            
                            
                            {/* Add more fields as necessary */}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseViewDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Destinations;
