import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react'
import { useGetStatsQuery } from 'state/api'
import Header from 'components/Header'

const StatCard = ({ title, value, color }) => (
  <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
    <Typography variant="subtitle1">{title}</Typography>
    <Typography variant="h4" style={{ color }}>{value}</Typography>
  </Paper>
);

const Dashboard = () => {
  const { data, error, isLoading } = useGetStatsQuery();

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const stats = data?.data || {};

  return (
    <Box m="1.5rem 2.5rem">
        <Header title="Dashboard" subtitle="Statistics from each section" />
        <Box sx={{ flexGrow: 1, padding: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard title="Total Blogs" value={stats.blogs || 0} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard title="Total Top Deals" value={stats.topDeals || 0 } />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard title="Total Destinations" value={stats.destinations || 0} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard title="Total Users" value={stats.users || 0} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard title="Total Villas" value={stats.villas || 0} color="orange" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard title="Number of Cars" value={stats.cars || 0} />
            </Grid>
          </Grid>
        </Box>
    </Box>
  )
}

export default Dashboard
