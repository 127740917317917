import React, { useState } from 'react';
import HomepageNav from 'components/HomepageNav';
import planeVideo from "../assets/plane.mp4";
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import Footer from 'components/Footer';
import PlaneEnquiry from 'components/PlaneEnquiry';
import FloatingSocialIcons from 'components/FloatingSocialIcons';
import visa from "../assets/visa.png"
import transfer from "../assets/transfer.png"
import flight from "../assets/flight.png"

const AirTickets = () => {
  const [showEnquiryForm, setShowEnquiryForm] = useState(false);

  const handleEnquireNowClick = () => {
    setShowEnquiryForm(true);
  };

  const handleCloseForm = () => {
    setShowEnquiryForm(false);
  };

  return (
    <div>
      <HomepageNav />

      <section className="p-5" style={{ backgroundColor: "white", position: "relative", textAlign: "center" }}>
        <div className="carousel-inner" style={{ position: "relative", width: "100%", height: "auto" }}>
          <div className="carousel-item active" style={{ position: "relative" }}>
            <video className="d-block w-100 video" autoPlay loop muted style={{ width: "100%", height: "100vh", objectFit: "cover" }}>
              <source src={planeVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)" }}></div>
          </div>
          <div className="carousel-caption d-flex flex-column justify-content-center align-items-center text-center h-100" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
            {/* <img src={logo} style={{height:"20%",}}/>  */}
            <h1 className="text-white fw-bold">Planning to Take A Flight?</h1>
            <h4 className="text-white">Book local or international tickets with Cupstone Travel at amazing rates</h4>
            <button
              className="learn-more"
              style={{
                padding: '0.75rem 1.5rem',
                fontSize: '1rem',
                color: '#228B22',
                backgroundColor: 'white',
                opacity: 0.4,
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                textDecoration: 'none',
                transition: 'background-color 0.5s, color 0.5s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#228B22';
                e.currentTarget.style.color = 'white';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'white';
                e.currentTarget.style.color = '#228B22';
              }}
              onClick={handleEnquireNowClick}
            >
              Enquire Now
            </button>

          </div>
        </div>
      </section>

      <section className="py-5" style={{ background: "white" }}>
        <div className="history-container">
          <div className="row justify-content-center">
            <div >
              <div className="text-center">
              
                <span className="text-muted">Air Traveling</span>
                <h2 className="display-6 fw-bold" style={{ color: '#008000' }}>Hassle-Free Trips With Us</h2>
                <div className="parent-container" style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
  <p className="lead display-10 col-md-8 text-center" style={{ color: "black" }}>
    Book your next adventure with us! Find the best deals on flights to your favorite destinations and experience seamless travel planning.
  </p>
</div>


                <section className="p-1" style={{ backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                  <Box m="1.5rem 2.5rem">
                    <div className="services-container">
                      <div className="row justify-content-center">
                        
                        <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                          <div className="mb-3">
                            <img src={flight} alt="Credit" className="business-image" />
                          </div>
                          <h4 className="fw-bold" style={{ color: "#008000" }}>Air Ticketing</h4>
                          <p style={{ color: "black" }}>Book your flights quickly and easily with our fast travel options.</p>
                        </div>

                        <div className="col-md-3 col-sm-6">
                          <div className="mb-3">
                            <img src={visa} alt="Centers" className="business-image" />
                          </div>
                          <h4 className="fw-bold" style={{ color: "#008000" }}>Visa Processing</h4>
                          <p style={{ color: "black" }}>Streamline your travel with our efficient visa processing services, making international journeys hassle-free.</p>
                        </div>

                        <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                          <div className="mb-3">
                            <img src={transfer} alt="Credit" className="business-image" />
                          </div>
                          <h4 className="fw-bold" style={{ color: "#008000" }}>Airport Transfer</h4>
                          <p style={{ color: "black" }}>Enjoy seamless airport transfers with our prompt and comfortable transportation services, making your arrival and departure smooth.</p>
                        </div>              
                      </div>
                    </div>
                    </Box>
                  </section>
                       <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#008000',
                      width: '200px',
                      height: '70px',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#006400', // Optional: darker green for hover state
                      },
                    }}
                    onClick={handleEnquireNowClick}
                  >
                    Enquire Now
                  </Button>
                
              </div>
            </div>
            
          </div>
        </div>
        <Container maxWidth="md" sx={{ my: 5 }}>
          <Grid container justifyContent="center" alignItems="center" spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" align="center" sx={{ color: '#008000' }}>
                ANYWHERE, ANYTIME.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" align="center" sx={{ color: '#008000' }}>
                24/7 SUPPORT.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" align="center" sx={{ color: '#008000' }}>
                ANYTIME RESERVATION.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
      <div>
        {showEnquiryForm && <PlaneEnquiry onClose={handleCloseForm} />}
      </div>

      <Footer />
      <div>
        <FloatingSocialIcons/>
      </div>
    </div>
  );
};

export default AirTickets;
