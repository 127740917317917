import React from 'react';
import "../styles/planeEnquiry.css";

const PlaneEnquiry = ({ onClose }) => {
  return (
    <div className="enquiry-form-container">
      <div className="enquiry-form-backdrop" onClick={onClose}></div>
      <div className="enquiry-form">
        <h2>Enquiry Form</h2>
        <form action="https://formsubmit.co/2a0c46b72f8c29e6f41c51036dad2c82" method="POST">
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input 
              type="text" 
              id="subject" 
              className="form-control" 
              name="subject" 
              value="Inquiring About Plane Ticket" 
              readOnly 
            />
          </div>
          <div className="form-group">
            <label>First Name</label>
            <input type="text" className="form-control" name='FirstName' placeholder="Enter first name" />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input type="text" className="form-control" name='LastName' placeholder="Enter last name" />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input type="text" className="form-control" name='PhoneNumber' placeholder="Enter phone number" />
          </div>
          <div className="form-group">
            <label>Departure Location</label>
            <input type="text" className="form-control" name='DepartureLocation' placeholder="Enter departure location" />
          </div>
          <div className="form-group">
            <label>Destination</label>
            <input type="text" className="form-control" name='Destination' placeholder="Enter destination location" />
          </div>
          <div className="form-group">
            <label>Date of Travel</label>
            <input type="date" name='DateOfTravel' className="form-control" />
          </div>
          <button type="submit" className="btn btn-primary" style={{ background: "#008000", borderBlockColor: "#008000" }}>Submit</button>
          <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
        </form>
      </div>
    </div>
  );
};

export default PlaneEnquiry;
