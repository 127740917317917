import React, { useState, useEffect, useMemo } from 'react';
import { Box, Card, CardContent, CardMedia, CardActionArea, Typography, TextField, Button } from '@mui/material';
import { useGetVillasQuery } from 'state/api';
import Footer from 'components/Footer';
import HomepageNav from 'components/HomepageNav';
import FloatingSocialIcons from 'components/FloatingSocialIcons';

const VillasPage = () => {
  const { data, isLoading, error } = useGetVillasQuery();
  const villas = useMemo(() => data?.data || [], [data]);

  const [filteredVillas, setFilteredVillas] = useState(villas);
  const [price, setPrice] = useState('');
  const [location, setLocation] = useState('');

  useEffect(() => {
    setFilteredVillas(villas);
  }, [villas]);

  const handleFilter = () => {
    let filtered = villas;
    if (price) {
      filtered = filtered.filter(villa => villa.price <= price);
    }
    if (location) {
      filtered = filtered.filter(villa => 
        `${villa.address.street} ${villa.address.estate} ${villa.address.city} ${villa.address.country}`.toLowerCase().includes(location.toLowerCase())
      );
    }
    setFilteredVillas(filtered);
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  const handleReset = () => {
    setPrice('');
    setLocation('');
    setFilteredVillas(villas);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div style={{ background: 'white' }}>
      <HomepageNav />
      <Box m="1.5rem 2.5rem">
        <Box sx={{ height: '80vh', my: 9, overflow: 'auto', p: 2 }}>
          <h1 style={{ color: '#008000', textAlign: "center", fontWeight: "bold", textDecoration: "underline" }}>Villas</h1>
          <Typography variant="h2" component="h4" gutterBottom style={{ color: '#008000', marginTop: "50px" }}>
            Search or Filter villas by Price or Location
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              mb: 2,
              '@media (max-width: 600px)': {
                flexDirection: 'column',
              },
            }}
          >
            <TextField
              label="Max Price"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#008000' },
                  '&:hover fieldset': { borderColor: '#008000' },
                  '&.Mui-focused fieldset': { borderColor: '#008000' },
                },
              }}
              InputLabelProps={{ style: { color: '#008000' } }}
              InputProps={{ style: { color: '#008000' } }}
            />
            <TextField
              label="Location i.e Nairobi"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#008000' },
                  '&:hover fieldset': { borderColor: '#008000' },
                  '&.Mui-focused fieldset': { borderColor: '#008000' },
                },
              }}
              InputLabelProps={{ style: { color: '#008000' } }}
              InputProps={{ style: { color: '#008000' } }}
            />
            <Button variant="contained" sx={{ backgroundColor: "#008000", color: "white" }} onClick={handleFilter}>Filter</Button>
            <Button variant="outlined" sx={{ color: "#008000", borderColor: "#008000" }} onClick={handleReset}>Reset</Button>
          </Box>
          {filteredVillas.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              {filteredVillas.map((villa) => (
                <Card key={villa._id} sx={{ maxWidth: 345 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="160"
                      image={villa.photos[0]?.url}
                      alt={villa.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {capitalizeWords(villa.title)}
                      </Typography>
                      <Typography gutterBottom variant="h5" component="div">
                        Price/Night: KES {new Intl.NumberFormat().format(villa.price)}
                      </Typography>
                      <Typography gutterBottom variant="body1" component="div">
                        {`${villa.address.street}, ${villa.address.estate}, ${villa.address.city}, ${villa.address.country}`}
                      </Typography>
                      <a
                        href={`/villasDetails/${villa._id}`}
                        className="btn"
                        style={{
                          backgroundColor: "#008000",
                          color: "white",
                          padding: "10px 20px",
                          borderRadius: "5px",
                          textDecoration: "none",
                          display: "inline-block",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        View House
                      </a>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
          ) : (
            <p style={{ color: 'black' }}>No villas available.</p>
          )}
        </Box>
      </Box>
      <Footer />
      <FloatingSocialIcons />
    </div>
  );
};

export default VillasPage;
