import React from 'react';
import { Box, Card, CardContent, CardMedia, CardActionArea, Typography } from '@mui/material';
import { useGetDestinationCategoryQuery } from 'state/api';
import Footer from 'components/Footer';
import HomepageNav from 'components/HomepageNav';
import { useParams } from 'react-router-dom';
import FloatingSocialIcons from 'components/FloatingSocialIcons';

const DestinationList = () => {
    const { category } = useParams();
    const { data, isLoading } = useGetDestinationCategoryQuery(category);
    const destinations = data?.data || []; // Access blogs array from the API response

    if (isLoading) {
        // Show a loading spinner while fetching data
        return <div>Loading...</div>;
      }

  

  return (
    <div style={{ background: 'white' }}>
      <HomepageNav />
      <div>
      <Box m="1.5rem 2.5rem">
        <Box sx={{ height: '75vh', my: 9, overflow: 'auto', p: 2 }}>
        <h1 style={{ color: '#008000', textAlign: "center", fontWeight: "bold", textDecoration: "underline",}}>{category} Destinations</h1>
          {destinations.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              {destinations.map((destination) => (
                <Card key={destination._id} sx={{ maxWidth: 345 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="160"
                      image={destination.photos[0]?.url}
                      alt={destination.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {destination.title}
                      </Typography>
                      <Typography gutterBottom variant="body3" component="div">
                        RefNo: {destination.refNo}
                      </Typography>
                      <a href={`/destinationPage/${destination._id}`} className="btn"
                          style={{
                            backgroundColor: "#008000",
                            color: "white",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            textDecoration: "none",
                            display: "inline-block",
                            textAlign: "center",
                          }}>View Destination</a>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
          ) : (
            <p style={{ color: 'black' }}>No {category} destinations available.</p>
          )}
        </Box>
        </Box>
      </div>
      <Footer />
      <FloatingSocialIcons/>
    </div>
  );
};

export default DestinationList;
