import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL || "https://cupstonetravel.com:8444/",
    prepareHeaders: (headers) => {
      // Get token from localStorage
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  reducerPath: "adminApi",
  tagTypes: ["User", "Blog", "Destination", "Villa", "Car", "TopDeal"],
  endpoints: (build) => ({
    registerUser: build.mutation({
      query: (userData) => ({
        url: "client/users",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    loginUser: build.mutation({
      query: (userData) => ({
        url: "client/users/login",
        method: "POST",
        body: userData,
      }),
    }),
    getUsers: build.query({
      query: () => ({
        url: "client/users",
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    createBlog: build.mutation({
      query: (blogData) => ({
        url: "management/blogs",
        method: "POST",
        body: blogData,
      }),
      invalidatesTags: ["Blog"],
    }),
    getBlogs: build.query({
      query: () => 'management/blogs', // Update this if the route is different
      providesTags: ["Blog"],
    }),
    createDestination: build.mutation({
      query: (formData) => ({
        url: '/management/destinations',
        method: 'POST',
        body: formData, // formData should be passed directly
        // Since it's FormData, no additional content-type needed
      }),
      invalidatesTags: ['Destination'],
    }),
    createTopDeal: build.mutation({
      query: (dealData) => ({
        url: '/management/top-deals',
        method: 'POST',
        body: dealData, // formData should be passed directly
        // Since it's FormData, no additional content-type needed
        }),
        invalidatesTags: ['TopDeal'],
    }),
    createVilla: build.mutation({
      query: (villaData) => ({
        url: '/management/villas',
        method: 'POST',
        body: villaData, // formData should be passed directly
        // Since it's FormData, no additional content-type needed
        }),
        invalidatesTags: ['Villa'],
    }),
    createCar: build.mutation({
      query: (carData) => ({
        url: '/management/cars',
        method: 'POST',
        body: carData, // formData should be passed directly
        // Since it's FormData, no additional content-type needed
        }),
        invalidatesTags: ['Car'],
    }),
    getDestinations: build.query({ 
      query: () => '/management/destinations', // Update this if the route is different
      providesTags: ["Destination"],
    }),
    getTopDeals: build.query({
      query: () => 'management/top-deals', // Update this if the route is different
      providesTags: ["TopDeal"],
    }),
    getVillas: build.query({
      query: () => '/management/villas', // Update this if the route is different
      providesTags: ["Villa"],
    }),
    getCar: build.query({
      query: () => '/management/cars', // Update this if the route is different
      providesTags: ["Car"],
    }),
    getCarCategory: build.query({
      query: (category) => `management/cars/category/${category}`,
      providesTags: ["CarCategory"],
    }),
    getDestinationCategory: build.query({
      query: (category) => `/management/destinations/category/${category}`,
      providesTags: ["DestinationCategory"],
    }),
    getTopDealCategory: build.query({
      query: (category) => `/management/top-deals/category/${category}`,
      providesTags: ["TopDealCategory"],
    }),
    getCarById: build.query({
      query: (id) => `management/cars/${id}`,
      providesTags: ["Car"], 
    }),
    getVillaById: build.query({
      query: (id) => `management/villas/${id}`,
      providesTags: ["Villa"], 
    }),
    getBlogById: build.query({
      query: (id) => `management/blogs/${id}`,
      providesTags: ["Blog"], 
    }),
    getDestinationById: build.query({
      query: (id) => `management/destinations/${id}`,
      providesTags: ["Destination"], 
    }),
    getTopDealById: build.query({
      query: (id) => `management/top-deals/${id}`,
      providesTags: ["TopDeal"], 
    }),
    getStats: build.query({
      query: () => '/general/stats',
      providesTags: ["Stats"],
    }),
    deleteDestinationById: build.mutation({
      query: (id) => ({
        url: `management/destinations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Destination'],
    }),
    deleteTopDealById: build.mutation({
      query: (id) => ({
        url: `management/top-deals/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TopDeal'],
    }),
    deleteVillaById: build.mutation({
      query: (id) => ({
        url: `management/villas/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Villa'],
    }),
    deleteCarById: build.mutation({
      query: (id) => ({
        url: `management/cars/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Car'],
    }),
    deleteBlogById: build.mutation({
      query: (id) => ({
        url: `management/blogs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Blog'],
    }),
    blockUserById: build.mutation({
      query: (id) => ({
        url: `general/users/${id}/block`,
        method: 'PATCH',
      }),
      invalidatesTags: ['User'],
    }),
    deleteUserById: build.mutation({
      query: (id) => ({
        url: `general/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    updateDestinationById: build.mutation({
      query: ({ id, formData }) => ({
        url: `/management/destinations/${id}`, // Use template literals correctly
        method: 'PUT', // Use PUT for update operations
        body: formData, // formData should be passed directly
        // Since it's FormData, no additional content-type needed
      }),
      invalidatesTags: ['Destination'],
    }),
    updateBlogById: build.mutation({
      query: ({ id, formData }) => ({
        url: `/management/blogs/${id}`, // Use template literals correctly
        method: 'PUT', // Use PUT for update operations
        body: formData, // formData should be passed directly
        // Since it's FormData, no additional content-type needed
      }),
      invalidatesTags: ['Blog'],
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useGetUsersQuery,
  useCreateBlogMutation,
  useGetBlogsQuery,
  useCreateDestinationMutation,
  useCreateTopDealMutation,
  useCreateVillaMutation,
  useCreateCarMutation,
  useGetDestinationsQuery,
  useGetTopDealsQuery,
  useGetVillasQuery,
  useGetCarQuery,
  useGetCarCategoryQuery,
  useGetDestinationCategoryQuery,
  useGetTopDealCategoryQuery,
  useGetCarByIdQuery,
  useGetVillaByIdQuery,
  useGetBlogByIdQuery,
  useGetDestinationByIdQuery,
  useGetTopDealByIdQuery,
  useGetStatsQuery,
  useDeleteDestinationByIdMutation,
  useDeleteTopDealByIdMutation,
  useDeleteVillaByIdMutation,
  useDeleteCarByIdMutation,
  useDeleteBlogByIdMutation,
  useBlockUserByIdMutation,
  useDeleteUserByIdMutation,
  useUpdateDestinationByIdMutation,
  useUpdateBlogByIdMutation,
} = api;
