import { React, useState } from 'react';
import Header from 'components/Header';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IoIosImages } from 'react-icons/io';
import { BiTrash } from 'react-icons/bi';
import ImageIcon from '@mui/icons-material/Image';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useCreateTopDealMutation } from 'state/api';
import { message } from 'antd';

const CreateTopDeal = () => {
  const [selectedGroup, setSelectedGroup] = useState('');
  const [photos, setPhotos] = useState([]);
  const [description, setDescription] = useState('');
  const [inclusions, setInclusions] = useState('');
  const [exclusions, setExclusions] = useState('');
  const [title, setTitle] = useState('');
  const [discountDeal, setDiscountDeal] = useState('');
  const [priceStart, setPriceStart] = useState('');

  const [createTopDeal] = useCreateTopDealMutation();

  // UPLOAD, DRAG AND DROP PHOTOS
  const handleUploadPhotos = (e) => {
    const newPhotos = e.target.files;
    setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
  };

  const handleDragPhoto = (result) => {
    if (!result.destination) return;
    const items = Array.from(photos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPhotos(items);
  };

  const handleRemovePhoto = (indexToRemove) => {
    setPhotos((prevPhotos) =>
      prevPhotos.filter((_, index) => index !== indexToRemove)
    );
  };

  // HANDLE QUILL TEXT AREA
  const handleQuillChange = (content, field) => {
    switch (field) {
      case 'description':
        setDescription(content);
        break;
      case 'inclusions':
        setInclusions(content);
        break;
      case 'exclusions':
        setExclusions(content);
        break;
      default:
        break;
    }
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  // Generate refNo
  const generateRefNo = (destinationGroup) => {
    const groupCode = destinationGroup
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
    const timestamp = Date.now();
    return `${groupCode}-${timestamp}`;
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();

    const refNo = generateRefNo(selectedGroup);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('destinationGroup', selectedGroup);
    formData.append('description', description);
    formData.append('inclusions', inclusions);
    formData.append('exclusions', exclusions);
    formData.append('discountDeal', discountDeal);
    formData.append('priceStart', priceStart);
    formData.append('refNo', refNo);
    photos.forEach(photo => formData.append('images', photo));

    try {
      const response = await createTopDeal(formData).unwrap();
      setTitle('');
      setSelectedGroup('');
      setDescription('');
      setPhotos([]);
      setInclusions('');
      setExclusions('');
      setDiscountDeal('');
      setPriceStart('');
      message.success('Top Deal created successfully');

      if (response.ok) {
        message.success('Top Deal created successfully');
      }
    } catch (error) {
      console.error('Error creating Top Deal:', error);
      message.error('Error creating Top Deal');
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Create a New Top Deal" subtitle="Write and publish a new top deal" />
      <Box sx={{ my: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              label="Destination Title"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="group-label">Destination Group</InputLabel>
              <Select
                labelId="group-label"
                id="group-select"
                value={selectedGroup}
                onChange={handleGroupChange}
                label="Destination Group"
              >
                <MenuItem value="Beach Vacation">Beach Vacation</MenuItem>
                <MenuItem value="Bush">Bush</MenuItem>
                <MenuItem value="Hiking & Camping">Hiking & Camping</MenuItem>
                <MenuItem value="Flight & Balloon Safari">
                  Flight & Balloon Safari
                </MenuItem>
                <MenuItem value="Photography Safaris">
                  Photography Safaris
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Discount Deal"
              value={discountDeal}
              onChange={(e) => setDiscountDeal(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Price Start From"
              value={priceStart}
              onChange={(e) => setPriceStart(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h6" gutterBottom>
          Destination Description
        </Typography>
        <ReactQuill
          theme="snow"
          value={description}
          onChange={(content) => handleQuillChange(content, 'description')}
          modules={{
            toolbar: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ size: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link', 'image'],
              ['clean'],
            ],
          }}
          formats={[
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'link',
            'image',
          ]}
          style={{ height: '200px' }}
        />
      </Box>

      <Box sx={{ mt: 10 }}>
        <Button
          variant="outlined"
          startIcon={<ImageIcon />}
          sx={{
            marginBottom: '30px',
          }}
        >
          <DragDropContext onDragEnd={handleDragPhoto}>
            <Droppable droppableId="photos" direction="horizontal">
              {(provided) => (
                <div
                  className="photos"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {photos.length < 1 && (
                    <>
                      <input
                        id="image"
                        type="file"
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={handleUploadPhotos}
                        multiple
                      />
                      <label htmlFor="image" className="alone">
                        <div className="icon">
                          <IoIosImages />
                        </div>
                        <p>Upload From Your Device</p>
                      </label>
                    </>
                  )}

                  {photos.length >= 1 && (
                    <>
                      {photos.map((photo, index) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="photo"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <img src={URL.createObjectURL(photo)} alt="blog" />
                              <button type="button" onClick={() => handleRemovePhoto(index)}>
                                <BiTrash />
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      <input
                        id="image"
                        type="file"
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={handleUploadPhotos}
                        multiple
                      />
                      <label htmlFor="image" className="together">
                        <div className="icon">
                          <IoIosImages />
                        </div>
                        <p>Upload From Your Device</p>
                      </label>
                    </>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Button>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h6" gutterBottom>
          Package Inclusions
        </Typography>
        <ReactQuill
          theme="snow"
          value={inclusions}
          onChange={(content) => handleQuillChange(content, 'inclusions')}
          modules={{
            toolbar: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ size: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link', 'image'],
              ['clean'],
            ],
          }}
          formats={[
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'link',
            'image',
          ]}
          style={{ height: '200px' }}
        />
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h6" gutterBottom>
          Package Exclusions
        </Typography>
        <ReactQuill
          theme="snow"
          value={exclusions}
          onChange={(content) => handleQuillChange(content, 'exclusions')}
          modules={{
            toolbar: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ size: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link', 'image'],
              ['clean'],
            ],
          }}
          formats={[
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'link',
            'image',
          ]}
          style={{ height: '200px' }}
        />
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Create Top Deal
        </Button>
      </Box>
    </Box>
  );
};

export default CreateTopDeal;
