export const tokensDark = {
    blue: {
        0: "#ffffff", // manually adjusted
        10: "#f6f6f6", // manually adjusted
        50: "#f0f0f0", // manually adjusted
        100: "#dde9e0",
        200: "#bad3c1",
        300: "#98bda3",
        400: "#75a784",
        500: "#539165",
        600: "#427451",
        700: "#32573d",
        800: "#213a28",
        900: "#111d14",
        1000: "#000000", // manually adjusted
    },

    primary: 
    {
        //orange
        100: "#f8ece1",
        200: "#f0d9c4",
        300: "#e9c6a6",
        400: "#e1b389",
        500: "#daa06b",
        600: "#ae8056",
        700: "#836040",
        800: "#57402b",
        900: "#2c2015"
    },

    secondary: {
        100: "#fff3cd",
        200: "#ffe69c",
        300: "#ffda6a",
        400: "#ffcd39",
        500: "#ffc107",
        600: "#cc9a06",
        700: "#997404",
        800: "#664d03",
        900: "#332701"
},

};

// function that reverses the color palette
function reverseTokens(tokensDark) {
    const reversedTokens = {};
    Object.entries(tokensDark).forEach(([key, val]) => {
      const keys = Object.keys(val);
      const values = Object.values(val);
      const length = keys.length;
      const reversedObj = {};
      for (let i = 0; i < length; i++) {
        reversedObj[keys[i]] = values[length - i - 1];
      }
      reversedTokens[key] = reversedObj;
    });
    return reversedTokens;
  }
  export const tokensLight = reverseTokens(tokensDark);
  
  // mui theme settings
  export const themeSettings = (mode) => {
    return {
      palette: {
        mode: mode,
        ...(mode === "dark"
          ? {
              // palette values for dark mode
              primary: {
                ...tokensDark.primary,
                main: tokensDark.primary[400],
                light: tokensDark.primary[400],
              },
              secondary: {
                ...tokensDark.secondary,
                main: tokensDark.secondary[300],
              },
              neutral: {
                ...tokensDark.blue,
                main: tokensDark.blue[500],
              },
              background: {
                default: tokensDark.primary[600],
                alt: tokensDark.primary[500],
              },
            }
          : {
              // palette values for light mode
              primary: {
                ...tokensLight.primary,
                main: tokensDark.blue[50],
                light: tokensDark.blue[100],
              },
              secondary: {
                ...tokensLight.secondary,
                main: tokensDark.secondary[600],
                light: tokensDark.secondary[700],
              },
              neutral: {
                ...tokensLight.blue,
                main: tokensDark.blue[500],
              },
              background: {
                default: tokensDark.blue[0],
                alt: tokensDark.blue[50],
              },
            }),
      },
      typography: {
        fontFamily: ["Manrope", "sans-serif"].join(","),
        fontSize: 12,
        h1: {
          fontFamily: ["Manrope", "sans-serif"].join(","),
          fontSize: 40,
        },
        h2: {
          fontFamily: ["Manrope", "sans-serif"].join(","),
          fontSize: 32,
        },
        h3: {
          fontFamily: ["Manrope", "sans-serif"].join(","),
          fontSize: 24,
        },
        h4: {
          fontFamily: ["Manrope", "sans-serif"].join(","),
          fontSize: 20,
        },
        h5: {
          fontFamily: ["Manrope", "sans-serif"].join(","),
          fontSize: 16,
        },
        h6: {
          fontFamily: ["Manrope", "sans-serif"].join(","),
          fontSize: 14,
        },
      },
    };
  };

