import React, { useState } from "react";
import Header from "components/Header";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { IoIosImages } from "react-icons/io";
import { BiTrash } from "react-icons/bi";
import ImageIcon from "@mui/icons-material/Image";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Add } from "@mui/icons-material";
import { useCreateDestinationMutation } from "state/api";
import { message } from "antd";

const CreateDestination = () => {
  const theme = useTheme();
  const [title, setTitle] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [description, setDescription] = useState("");
  const [inclusions, setInclusions] = useState("");
  const [exclusions, setExclusions] = useState("");
  const [priceStart, setPriceStart] = useState('');

  const [createDestination] = useCreateDestinationMutation();

  // UPLOAD, DRAG AND DROP PHOTOS
  const [photos, setPhotos] = useState([]);

  const handleUploadPhotos = (e) => {
    const newPhotos = e.target.files;
    setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
  };

  const handleDragPhoto = (result) => {
    if (!result.destination) return;
    const items = Array.from(photos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPhotos(items);
  };

  const handleRemovePhoto = (indexToRemove) => {
    setPhotos((prevPhotos) =>
      prevPhotos.filter((_, index) => index !== indexToRemove)
    );
  };
  // END UPLOAD, DRAG AND DROP PHOTOS

  // HANDLE TEXTAREA QUILL CHANGES
  const handleQuillChange = (content, field) => {
    console.log("Quill Change:", { field, content }); // Add this to debug
    switch (field) {
      case "description":
        setDescription(content);
        break;
      case "inclusions":
        setInclusions(content);
        break;
      case "exclusions":
        setExclusions(content);
        break;
      default:
        break;
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  // Generate a unique reference number
  const generateRefNo = (country) => {
    const timestamp = Date.now(); // Get the current timestamp
    return `${country.replace(/\s+/g, '').toUpperCase()}-${timestamp}`; // Combine country and timestamp
  };

  // CREATE NEW DESTINATION
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const refNo = generateRefNo(selectedCountry); // Generate the reference number

      // Initialize FormData
      const formData = new FormData();
      formData.append("title", title);
      formData.append("country", selectedCountry);
      formData.append('priceStart', priceStart);
      formData.append("description", description);
      formData.append("inclusions", inclusions);
      formData.append("exclusions", exclusions);
      formData.append("refNo", refNo); // Add the reference number to the form data
      photos.forEach((photo) => formData.append("images", photo));

      // Log formData contents
      // for (let pair of formData.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }

      // Make the request
      const response = await createDestination(formData).unwrap(); // Pass formData directly
      setTitle("");
      setSelectedCountry("");
      setPriceStart('');
      setDescription("");
      setPhotos([]);
      setInclusions("");
      setExclusions("");
      message.success("Destination created successfully");

      if (response.ok) {
        message.success("Destination created successfully");
      }
    } catch (error) {
      console.error("Error creating destination:", error);
      alert(`Error creating destination: ${error.message}`);
      message.error("Error creating destination");
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Create a New Destination"
        subtitle="Write and publish a new destination"
      />
      <Box
        component="form"
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
          "& > *": {
            mt: 5,
          },
        }}
        onSubmit={handleSubmit}
      >
        <Box sx={{ my: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Destination Title"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="country-label">Destination Country</InputLabel>
                <Select
                  labelId="country-label"
                  id="country-select"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  label="Destination Country"
                >
                  <MenuItem value="Kenya">Kenya</MenuItem>
                  <MenuItem value="Uganda">Uganda</MenuItem>
                  <MenuItem value="Tanzania">Tanzania</MenuItem>
                  <MenuItem value="Rwanda">Rwanda</MenuItem>
                  <MenuItem value="Zambia">Zambia | Botswana | Namibia</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Price Start From"
              value={priceStart}
              onChange={(e) => setPriceStart(e.target.value)}
              variant="outlined"
            />
          </Grid>
          </Grid>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            Destination Description
          </Typography>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={(content) => handleQuillChange(content, "description")}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ size: [] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image"],
                ["clean"],
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "link",
              "image",
            ]}
            style={{ height: "200px" }}
          />
        </Box>

        <Box sx={{ mt: 10 }}>
          <Button
            variant="outlined"
            startIcon={<ImageIcon />}
            sx={{
              marginBottom: "30px",
            }}
          >
            <DragDropContext onDragEnd={handleDragPhoto}>
              <Droppable droppableId="photos" direction="horizontal">
                {(provided) => (
                  <div
                    className="photos"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {photos.length < 1 && (
                      <>
                        <input
                          id="image"
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={handleUploadPhotos}
                          multiple
                        />
                        <label htmlFor="image" className="alone">
                          <div className="icon">
                            <IoIosImages />
                          </div>
                          <p>Upload From Your Device</p>
                        </label>
                      </>
                    )}

                    {photos.length >= 1 && (
                      <>
                        {photos.map((photo, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="photo"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <img
                                  src={URL.createObjectURL(photo)}
                                  alt="blog"
                                />
                                <button
                                  type="button"
                                  onClick={() => handleRemovePhoto(index)}
                                >
                                  <BiTrash />
                                </button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        <input
                          id="image"
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={handleUploadPhotos}
                          multiple
                        />
                        <label htmlFor="image" className="together">
                          <div className="icon">
                            <IoIosImages />
                          </div>
                          <p>Upload From Your Device</p>
                        </label>
                      </>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Button>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            Destination Inclusions
          </Typography>
          <ReactQuill
            theme="snow"
            value={inclusions}
            onChange={(content) => handleQuillChange(content, "inclusions")}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ size: [] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image"],
                ["clean"],
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "link",
              "image",
            ]}
            style={{ height: "200px" }}
          />
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            Destination Exclusions
          </Typography>
          <ReactQuill
            theme="snow"
            value={exclusions}
            onChange={(content) => handleQuillChange(content, "exclusions")}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ size: [] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image"],
                ["clean"],
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "link",
              "image",
            ]}
            style={{ height: "200px" }}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="end"
          p="1rem"
          backgroundColor={theme.palette.background.alt}
          borderRadius="0.55rem"
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            type="submit"
          >
            Create New Destination
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateDestination;
