import React, {useState}from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
  Typography
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import loginImage from '../assets/loginImage.jpg'
import Header from 'components/Header';
import { useNavigate } from 'react-router-dom';
import { useRegisterUserMutation } from 'state/api';
import { message } from 'antd';



const CreateUser = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        username: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [registerUser] = useRegisterUserMutation();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formValues.password !== formValues.confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }
        setErrorMessage('');
        try {
            const { confirmPassword, ...userPayload } = formValues; // Remove confirmPassword
            await registerUser(userPayload).unwrap();
            message.success('User created successfully!');
            setFormValues({
                username: '',
                email: '',
                phone: '',
                password: '',
                confirmPassword: ''
            }); // Clear form values
            navigate('/users');
        } catch (error) {
            console.error('Error creating user:', error.message);
            message.error('Error creating user');
        }
    };
    
  return (
    <Box  m="1.5rem 2.5rem">
    <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={12} md={6}>
            <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > *': {
                m: 2.5,
                width: '100%'
                }
            }}
            >
            <Header title="Create Account"/>

            <TextField
                margin='10px'
                label="Full Names"
                name="username"
                placeholder='Enter Full Names'
                variant="outlined"
                value={formValues.username}
                onChange={handleChange}
                sx={{
                    marginBottom:"30px"
                }}
            />
            <TextField
                label="Email"
                name="email"
                placeholder="example.email@gmail.com"
                variant="outlined"
                value={formValues.email}
                onChange={handleChange}
                sx={{
                    marginBottom:"30px"
                }}
            />
            <TextField
            label="Phone"
            name="phone"
            placeholder="Enter Phone Number"
            variant="outlined"
            value={formValues.phone}
            onChange={handleChange}
            sx={{
                marginBottom:"30px"
            }}
            />
            <TextField
                label="Password"
                name="password"
                placeholder="Enter at least 8+ characters"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={formValues.password}
                onChange={handleChange}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </InputAdornment>
                )
                }}
                sx={{
                    marginBottom:"30px"
                }}
            />
            <TextField
                label="Confirm Password"
                name="confirmPassword"
                placeholder="Enter at least 8+ characters"
                variant="outlined"
                type={showConfirmPassword ? 'text' : 'password'}
                value={formValues.confirmPassword}
                onChange={handleChange}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                    >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </InputAdornment>
                )
                }}
                sx={{
                    marginBottom:"30px"
                }}
            />
                {errorMessage && (
                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                        {errorMessage}
                    </Typography>
                )}
            <Button variant="outlined" color="primary" size="large" type='submit'
             sx={{
                color: theme.palette.secondary[100],
                borderColor: theme.palette.secondary[300]
                }} >
                Create Account
            </Button>
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <img
            src={loginImage} 
            alt="Scenic"
            showLoading
            fit="cover"
            style={{ width: '80%', height: '70%', borderRadius: '8px', marginLeft:"10px"}}
            
            />
        </Grid>
        </Grid>
    </Container>
    </Box>
  )
}

export default CreateUser
