import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  TextField,
  Button,
  Autocomplete
} from '@mui/material';
import { useGetCarCategoryQuery } from 'state/api';
import Footer from 'components/Footer';
import HomepageNav from 'components/HomepageNav';
import { useParams } from 'react-router-dom';
import FloatingSocialIcons from 'components/FloatingSocialIcons';
import { Counties } from 'state/countiesData';

const CarList = () => {
  const { category } = useParams();
  const { data, isLoading } = useGetCarCategoryQuery(category);
  const cars = useMemo(() => data?.data || [], [data]);

  const [filteredCars, setFilteredCars] = useState(cars);
  const [price, setPrice] = useState('');
  const [location, setLocation] = useState('');

  useEffect(() => {
    setFilteredCars(cars);
  }, [cars]);

  const handleFilter = () => {
    let filtered = cars;
    if (price) {
      filtered = filtered.filter(car => car.price <= price);
    }
    if (location) {
      filtered = filtered.filter(car => car.location.toLowerCase().includes(location.toLowerCase()));
    }
    setFilteredCars(filtered);
  };

  const handleReset = () => {
    setPrice('');
    setLocation('');
    setFilteredCars(cars);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ background: 'white' }}>
      <HomepageNav />
      <Box m="1.5rem 2.5rem">
        <Box sx={{ height: '80vh', my: 9, overflow: 'auto', p: 2 }}>
          <h1 style={{ color: '#008000', textAlign: "center", fontWeight: "bold", textDecoration: "underline" }}>{category} Cars</h1>
          <Typography variant="h2" component="h4" gutterBottom style={{ color: '#008000', marginTop: "50px" }}>
            Search or Filter cars by Price or Location
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              mb: 2,
              '@media (max-width: 600px)': {
                flexDirection: 'column',
              },
            }}
          >
            <TextField
              label="Max Price"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#008000',
                  },
                  '&:hover fieldset': {
                    borderColor: '#008000',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#008000',
                  },
                },
              }}
              InputLabelProps={{
                style: { color: '#008000' },
              }}
              InputProps={{
                style: { color: '#008000' },
              }}
            />
            <Autocomplete
              options={Counties.map((county) => county.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location by county"
                  variant="outlined"
                  fullWidth
                  style={{ backgroundColor: "white", color: "black" }}
                  InputLabelProps={{ style: { color: "black" } }}
                  InputProps={{ ...params.InputProps, style: { color: "black" } }}
                />
              )}
              value={location}
              onChange={(event, newValue) => {
                setLocation(newValue);
              }}
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#008000',
                  },
                  '&:hover fieldset': {
                    borderColor: '#008000',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#008000',
                  },
                },
              }}
            />
            <Button variant="contained" sx={{ backgroundColor: "#008000", color: "white" }} onClick={handleFilter}>Filter</Button>
            <Button variant="outlined" sx={{ color: "#008000", borderColor: "#008000" }} onClick={handleReset}>Reset</Button>
          </Box>
          {filteredCars.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              {filteredCars.map((car) => (
                <Card key={car._id} sx={{ maxWidth: 345 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={car.photos[0]?.url}
                      alt={car.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {car.title}
                      </Typography>
                      <Typography gutterBottom variant="h5" component="div">
                        Price/Day: KES {new Intl.NumberFormat().format(car.price)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Location: {car.location}
                      </Typography>
                      <a href={`/carPage/${car._id}`} className="btn"
                        style={{
                          backgroundColor: "#008000",
                          color: "white",
                          padding: "10px 20px",
                          borderRadius: "5px",
                          textDecoration: "none",
                          display: "inline-block",
                          textAlign: "center",
                        }}>View Car</a>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
          ) : (
            <p style={{ color: 'black' }}>No {category} cars available.</p>
          )}
        </Box>
      </Box>
      <Footer />
      <FloatingSocialIcons />
    </div>
  );
};

export default CarList;
