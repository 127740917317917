import React, { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  Container,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetDestinationByIdQuery } from "state/api";
import Footer from "components/Footer";
import HomepageNav from "components/HomepageNav";
import styled from "styled-components";
import { motion } from "framer-motion";
import FloatingSocialIcons from "components/FloatingSocialIcons";

const StyledHeroImage = styled(motion.img)`
  width: 100%;
  height: 75vh;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledContent = styled.div`
  margin: 20px 0;
  line-height: 1.6;
  font-size: 14px;
`;

const StyledAuthorDate = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #888;
  margin-top: 20px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1.5fr;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const FormBox = styled.form`
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #84B213FF;
`;

const StyledTextField = styled(TextField)`
  & label {
    color: #84B213FF;
  }
  & .MuiInputBase-input {
    color: #000;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #84B213FF;
    }
    &:hover fieldset {
      border-color: #84B213FF;
    }
    &.Mui-focused fieldset {
      border-color: #84B213FF;
    }
  }
`;

const SubmitButton = styled(Button)`
  background-color: #84B213FF;
  &:hover {
    background-color: #74a313;
  }
`;

const DestinationPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetDestinationByIdQuery(id);
  const [open, setOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const destination = data?.data[0];

  if (!destination) {
    return <div>Destination not found</div>;
  }

  const handleClickOpen = (photo) => {
    setSelectedPhoto(photo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPhoto(null);
  };

  return (
    <div
      style={{ background: "#E8EDF4FF", minHeight: "100vh", color: "black" }}
    >
      <HomepageNav />
      <Container maxWidth="lg" sx={{ p: 5 }}>
        <Box>
          <Box sx={{ mt: 5 }}>
            {destination.photos[0] && (
              <StyledHeroImage
                src={destination.photos[0].url}
                alt={destination.title}
                onClick={() => handleClickOpen(destination.photos[0].url)}
                whileHover={{ scale: 1.05 }}
              />
            )}
          </Box>

          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              color: "#84B213FF",
              fontWeight: "bold",
              textAlign: "center",
              mt: 5,
            }}
          >
            {destination.title}
          </Typography>

          <StyledAuthorDate>
            <Typography variant="body2">{destination.country}</Typography>
          </StyledAuthorDate>

          <GridContainer>
            <div>
              <Typography
                variant="h2"
                component="h3"
                gutterBottom
                style={{ color: "#84B213FF", fontWeight: "bold" }}
              >
                Details
              </Typography>
              <Box>
              <Box sx={{ mb: 4 }}>
                  <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    style={{ color: "#008000"}}
                  >
                    Pricing
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Card>
                        <CardContent>
                          <Typography
                            variant="h4"
                            component="h4"
                            style={{ color: "#008000" }}
                          >
                            Reference No.
                          </Typography>
                          <Typography variant="h6">{destination.refNo}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Card>
                        <CardContent>
                          <Typography
                            variant="h4"
                            component="h4"
                            style={{ color: "#008000" }}
                          >
                            Price Start From
                          </Typography>
                          <Typography variant="body1">KES: {new Intl.NumberFormat().format(destination.priceStart)}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
                <Typography
                  variant="h2"
                  component="h4"
                  gutterBottom
                  style={{ color: "#84B213FF", marginTop: "20px" }}
                >
                  Description
                </Typography>
                <StyledContent
                  dangerouslySetInnerHTML={{ __html: destination.description }}
                />
              </Box>
              <Box>
                <Typography
                  variant="h2"
                  component="h4"
                  gutterBottom
                  style={{ color: "#84B213FF", marginTop: "20px" }}
                >
                  Inclusions
                </Typography>
                <StyledContent
                  dangerouslySetInnerHTML={{ __html: destination.inclusions }}
                />
              </Box>
              <Box>
                <Typography
                  variant="h2"
                  component="h4"
                  gutterBottom
                  style={{ color: "#84B213FF", marginTop: "20px" }}
                >
                  Exclusions
                </Typography>
                <StyledContent
                  dangerouslySetInnerHTML={{ __html: destination.exclusions }}
                />
              </Box>
            </div>

            <FormBox action="https://formsubmit.co/d64a462948377481836474954a60ffbc" method="POST" >
              <Typography variant="h4" component="h4" gutterBottom sx={{color:'#84B213FF'}}>
                Inquire Now
              </Typography>
              <StyledTextField
                variant="outlined"
                fullWidth
                required
                name="Subject"
                value={`Enquiring about ${destination.title}`}
                InputProps={{
                  style: { color: 'black' },
                  readOnly: true,
                }}
              />
              <StyledTextField
                fullWidth
                margin="normal"
                label="Your name"
                variant="outlined"
                name="FullName"
              />
              <StyledTextField
                fullWidth
                margin="normal"
                label="Your phone"
                variant="outlined"
                name="PhoneNumber"
                InputProps={{
                  startAdornment: (
                    <span style={{ marginRight: 8, color:'#84B213FF'}}>+254</span>
                  ),
                }}
              />
              <StyledTextField
                fullWidth
                margin="normal"
                label="Your email"
                name="Email"
                variant="outlined"
              />
              <StyledTextField
                fullWidth
                margin="normal"
                label="Destination Reference Number i.e KENYA-123...."
                name="DestinationReferenceNumber"
                variant="outlined"
              />
              <StyledTextField
              fullWidth
              margin="normal"
              label="Your Enquiries"
              name="Enquiries"
              variant="outlined"
              multiline
              rows={4}
            />
              <SubmitButton
                variant="contained"
                fullWidth
                type="submit"
                style={{ marginTop: 16 }}
              >
                Submit
              </SubmitButton>
            </FormBox>
          </GridContainer>

          <StyledAuthorDate>
            <Typography variant="body2">By Cupstone Travel LTD</Typography>
            <Typography variant="body2">
              {new Date(destination.createdAt).toLocaleDateString()}
            </Typography>
          </StyledAuthorDate>
        </Box>
      </Container>
      <Footer />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <img
            src={selectedPhoto}
            alt={destination.title}
            style={{ width: "100%" }}
          />
        </DialogContent>
      </Dialog>
      <FloatingSocialIcons/>
      
    </div>
  );
};

export default DestinationPage;
