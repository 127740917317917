import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/home.css"
import kenya from "../assets/Kenya.webp"
import kenya_Tanzania from "../assets/Kenya-Tanzania.jpg"
import rwanda from "../assets/Rwanda.jpg"
import uganda from "../assets/Uganda.jpg"
import zambia from "../assets/Zambia.jpg"
import tanzania from "../assets/Tanzania.jpg"
import beach from "../assets/beach.jpg"
import bush from "../assets/bush.jpg"
import balloon from "../assets/balloon.jpg"
import hiking from "../assets/hiking.jpg"
import photography from "../assets/photography.jpg" 
const destinations = [
    { name: 'Kenya', image: kenya },
    { name: 'Kenya & Tanzania',  image: kenya_Tanzania },
    { name: 'Rwanda', image: rwanda },
    { name: 'Tanzania', image: tanzania},
    { name: 'Uganda', image: uganda },
    { name: 'Zambia', image: zambia },
  ];

  const deals = [
    { name: 'Beach Vacation', image: beach },
    { name: 'Bush', image: bush },
    { name: 'Hiking & Camping', image: hiking },
    { name: 'Flight & Balloon Safari', image: balloon},
    { name: 'Photography Safaris', image: photography},
  ];

const HomepageDestination = () => {
  return (
    <div>
    <section className="destinations-section" id='destinations-section' style={{backgroundColor:"white"}}>
    <h2 className="text-center fw-bold" style={{color:"#008000"}}>Our Destinations</h2>
    <p className="text-center" style={{color:"#008000"}}>Explore Our Local Popular Travel Destinations</p>
    <div className="container">
      <div className="row">
        {destinations.map((destination, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card destination-card animate-fade-in">
              <img src={destination.image} className="card-img-top" alt={destination.name} />
              <div className="card-img-overlay d-flex flex-column justify-content-end">
                <h5 className="card-title">{destination.name}</h5>
                <a href={`/destinationList/${destination.name}`} className="btn btn-primary">View Safaris</a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
  <section className="destinations-section" id='topDeal-section' style={{backgroundColor:"white"}}>
    <h2 className="text-center fw-bold" style={{color:"#008000"}}>Top Deals</h2>
    <p className="text-center" style={{color:"#008000"}}>Get the best affordable deals at discount</p>
    <div className="container">
      <div className="row">
        {deals.map((deal, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card destination-card animate-fade-in">
              <img src={deal.image} className="card-img-top" alt={deal.name} />
              <div className="card-img-overlay d-flex flex-column justify-content-end">
                <h5 className="card-title">{deal.name}</h5>
                <a href={`/topDealList/${deal.name}`} className="btn btn-primary">View Safaris</a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
  </div>
  )
}

export default HomepageDestination
