import React from 'react';
import Header from 'components/Header';
import { Box, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add, Visibility } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { message } from 'antd';
import { useGetBlogsQuery, useDeleteBlogByIdMutation } from 'state/api';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import styled from "styled-components";
const StyledContent = styled.div`
  margin: 20px 0;
  line-height: 1.6;
`;

const TourBlogs = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { data, isLoading } = useGetBlogsQuery();
    const [deleteBlogById] = useDeleteBlogByIdMutation();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedBlogId, setSelectedBlogId] = React.useState(null);
    const [openViewDialog, setOpenViewDialog] = React.useState(false);
    const [selectedBlog, setSelectedBlog] = React.useState(null);

    const handleDeleteClick = (blogId) => {
        setSelectedBlogId(blogId);
        setOpenDialog(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteBlogById(selectedBlogId).unwrap();
            message.success('Blog deleted successfully');
            // Optionally refetch the blogs or update the UI
        } catch (error) {
            message.error('Failed to delete Blog');
        } finally {
            setOpenDialog(false);
        }
    };

    const handleCancelDelete = () => {
        setOpenDialog(false);
    };

    const handleViewClick = (blog) => {
        setSelectedBlog(blog);
        setOpenViewDialog(true);
    };

    const handleCloseViewDialog = () => {
        setOpenViewDialog(false);
        setSelectedBlog(null);
    };

    const columns = [
        {
            field: 'photos',
            headerName: 'Photos',
            flex: 1,
            width: 100,
            height: 100,
            renderCell: (params) => {
                const photoUrl = params.value[0]?.url;
                return <img src={photoUrl} alt="Blog" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />;
            },
        },
        {
            field: '_id',
            headerName: 'ID',
            flex: 1,
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 0.7,
        },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            flex: 0.7,
        },
        {
            field: 'view',
            headerName: 'View',
            flex: 0.5,
            renderCell: (params) => (
                <Box>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Visibility/>}
                        onClick={() => handleViewClick(params.row)}
                        sx={{ mr: 1 }}
                    >
                        View
                    </Button>
                </Box>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 0.5,
            renderCell: (params) => (
                <Box>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteClick(params.row._id)}
                    >
                        Delete
                    </Button>
                </Box>
            ),
        },
    ];

    return (
        <Box m="1.5rem 2.5rem">
            <Header title="Blogs" subtitle="Entire list of Blogs" />
            <Box
                mt="40px"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    loading={isLoading || !data}
                    getRowId={(row) => row._id}
                    rows={data?.data || []}
                    columns={columns}
                    rowHeight={100}
                />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ my: '1.5rem' }}>
                <Button
                    variant="outlined"
                    sx={{
                        color: theme.palette.secondary[100],
                        borderColor: theme.palette.secondary[300],
                    }}
                    endIcon={<Add />}
                    onClick={() => navigate('/createblog')}
                >
                    Add Blog
                </Button>
            </Box>

            <Dialog open={openDialog} onClose={handleCancelDelete}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete this Blog?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openViewDialog} onClose={handleCloseViewDialog}>
                <DialogTitle>Blog Details</DialogTitle>
                <DialogContent>
                    {selectedBlog && (
                        <Box>
                            <Typography variant="h6">{selectedBlog.title}</Typography>
                            <StyledContent dangerouslySetInnerHTML={{ __html: selectedBlog.content }} />
                            {/* Add more fields as necessary */}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseViewDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TourBlogs;
