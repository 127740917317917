import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Testimonials = () => {
  return (
    <div>
        <section class="py-4" style={{backgroundColor:"white"}}>
            <div class="testimony-container">
                <div class="row justify-content-center text-center mb-2 mb-lg-4">
                    <div class="col-12 col-lg-8 col-xxl-7 text-center mx-auto">
                        <span class="text-muted">Raving Customers</span>
                        <h1 class="display-7 fw-bold" style={{color:"#008000"}}>Our Testimonials</h1>
                        <p class="lead" style={{color:"#008000"}}>Here's what our satisfied customers have to say about their experience with us</p>
                    </div>
                </div>
                <div class="row py-4">
                    <div class="col-md-4 text-center">
                        <div class="bg-light p-3 p-lg-5 mb-4">
                            <div class="text-dark mb-2">
                                <svg class="bi bi-quote" fill="currentColor" height="48" viewbox="0 0 16 16" width="48" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"></path></svg>
                            </div>
                            <p style={{color:"black"}}>My wife and I booked a trip to Maasai Mara with Cupstone Travel, and it was an eye-opening experience. 
                                Amidst our hectic work schedules, this journey showed us the importance of taking a break. 
                                From luxurious accommodations blending seamlessly with nature to insightful game drives led by knowledgeable guides, 
                                every aspect of our trip was meticulously planned. The highlight was witnessing the Mara's wildlife in their natural 
                                habitat and soaking in breathtaking sunsets</p>
                        </div>
                        <div class="mb-5 mb-lg-0">
                            <img alt="" class="rounded-circle" height="96" src="https://freefrontend.dev/assets/square-small.png" width="96"/>
                            <h5 class="fw-bold mt-4" style={{color:"#008000"}}>JOEL(2023)</h5>
                            <div class="text-muted">
                                Client
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-center">
                        <div className="text-white p-3 p-lg-5 mb-4" style={{ backgroundColor: "#008000" }}>
                            <div class="text-white mb-2">
                                <svg class="bi bi-quote" fill="currentColor" height="48" viewbox="0 0 16 16" width="48" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"></path></svg>
                            </div>
                            <p>I had an exceptional experience with Cupstone Travel, and their exceptional handling of a difficult situation truly stood out. 
                                When I was unexpectedly left by my flight, they came to my rescue swiftly and effectively, ensuring minimal disruption to my trip.
                                 Their proactive and caring approach reassured me throughout, and I was able to continue enjoying my journey without stress. 
                                Beyond this incident, every aspect of my tour was flawlessly executed</p>
                        </div>
                        <div class="mb-5 mb-lg-0">
                            <img alt="" class="rounded-circle" height="96" src="https://freefrontend.dev/assets/square-small.png" width="96"/>
                            <h5 class="fw-bold mt-4" style={{color:"#008000"}}>Adebayo (2024)</h5>
                            <div class="text-muted">
                                Client
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="bg-light p-3 p-lg-5 mb-4">
                            <div class="text-dark mb-2">
                                <svg class="bi bi-quote" fill="currentColor" height="48" viewbox="0 0 16 16" width="48" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"></path></svg>
                            </div>
                            <p style={{color:"black"}}>Our experience with Cupstone Travel Limited was nothing short of extraordinary. From the moment we arrived, we were warmly welcomed and expertly guided through every aspect of our journey. 
                                The accommodations were luxurious and thoughtfully chosen, providing a perfect blend of comfort and charm</p>
                        </div>
                        <div class="mb-5 mb-lg-0">
                            <img alt="" class="rounded-circle" height="96" src="https://freefrontend.dev/assets/square-small.png" width="96"/>
                            <h5 class="fw-bold mt-4" style={{color:"#008000"}}>Mathews (2024)</h5>
                            <div class="text-muted">
                                Client
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
    </div>
  )
}

export default Testimonials
